
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { mdiDotsVertical } from "@mdi/js";
import {ListDataSource} from "@/data/List/ListDataSource";
import { Filter } from "@/cms-services/filter";
import EditorString from './components/editor/string.vue';
import EditorNumber from './components/editor/number.vue';
import EditorColor from './components/editor/color.vue';
import EditorEnum from './components/editor/enum.vue';
import EditorBoolean from './components/editor/bool.vue';
import EditorArray from './components/editor/array.vue';
import draggable from "vuedraggable";

@Component({
  components: {
    draggable,
    EditorString,
    EditorNumber,
    EditorColor,
    EditorEnum,
    EditorBoolean,
    EditorArray,
  },
})
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "commodity",
  });

  productParamDataSource:ListDataSource = new ListDataSource({
    className:"ProductParam",
    config:{
      filter:JSON.stringify([
        new Filter("productId", +this.$route.params.id)
      ])
    }
  })

  productModelDataSource:ListDataSource = new ListDataSource({
    className:"ProductModel",
    config:{}
  })


  productClassParamGroupDataSource:ListDataSource = new ListDataSource({
    className:"ProductClassParamGroup",
    config:{
      orderFieldName:"Priority",
      orderFieldDirection:"ASC",
    },
  })


  isCorrectEditor = (param) => {
    try {
      return JSON.parse(param.editor);
    } catch (e) {
      return false;
    }
  };

  getEditorName = (param) => {
    return `editor-${param.editor}`;
  };

  getEditor = (param) => param;
  hasParams = (group) => group?.params?.length > 0;


  get hasGroup() {
    return this.productClassParamGroupDataSource.items?.length > 0;
  }

  get caption() {
    return this.dataSource.model?.caption;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Товары",
        to: "/manage/commodities/",
        exact: true,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }
  icons: any = {
    dotsVertical: mdiDotsVertical,
  };

  routeToPage() {
    this.$router.push({
      name: "product-modification",
      params: {
        productId: this.$route.params.id,
      },
      query: {
        caption: this.caption,
      },
    });
  }

  async updateFilter(){
    this.productClassParamGroupDataSource.config.filter = JSON.stringify([
        new Filter("productClassId",this.dataSource.model.productClassId)
    ]);
    this.productModelDataSource.config.filter = JSON.stringify([
      new Filter("productClassId",this.dataSource.model.productClassId)
    ]);
    await this.productClassParamGroupDataSource.get();
    await this.productModelDataSource.get();
  }

  async created(){
    await Promise.all([
      this.dataSource.get(),
      this.dataSource.getMetaData(),
      this.productParamDataSource.get()
    ])
    if(this.dataSource.model.productClassId != null){
      this.productClassParamGroupDataSource.config.filter = JSON.stringify([
        new Filter("productClassId",this.dataSource.model.productClassId)
      ]);
      this.productModelDataSource.config.filter = JSON.stringify([
        new Filter("productClassId", this.dataSource.model.productClassId)
      ]);
      await this.productClassParamGroupDataSource.get();
      await this.productModelDataSource.get();
    }
  }

}
